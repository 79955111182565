.Mods {
  padding-top: var(--navbar-height);
  display: flex;
  flex-direction: column;

  &__Title {
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
    text-decoration: underline var(--accent-color);
    margin-bottom: 8px;
  }

  &__Items {
    display: flex;
    
    @media screen and (max-width: 997px) {
      flex-direction: column;
    }
  }
}