.ProjectStatus {
  font-size: 16px;
  font-weight: 300;
  height: fit-content;
  padding: 2px 6px;

  &__Development {
    background-color: #ff9c1b;
    box-shadow: 0 0 5px 2px #ff9c1b;
  }
  &__Release {
    background-color: #ab1bff;
    box-shadow: 0 0 5px 2px #ab1bff;
  }
  &__Freeze {
    background-color: #19b3a6;
    box-shadow: 0 0 5px 2px #19b3a6;
  }
  &__Dropped {
    background-color: #ff1b1b;
    box-shadow: 0 0 5px 2px #ff1b1b;
  }
}