@import "../../Constants";

.Navbar {
  position: fixed;
  top: 0;
  height: var(--navbar-height);
  border-bottom: 1px solid var(--accent-color);
  width: 100%;
  background-color: #424;

  &__Container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media screen and (min-width: calc(#{$containerWidth} + 1px)) {
      width: var(--container-witdh);
    }
    @media screen and (max-width: #{$containerWidth}) {
      width: 100%;
    }

    &_Link {
      font-size: 20px;

      &:hover {
        text-decoration: underline var(--accent-color);
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}