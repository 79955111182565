@import "../../Constants";

.ModCard {
  @media screen and (max-width: $containerWidth) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  @media screen and (min-width: $containerWidth+1) {
    width: 50%;
  }

  background: #424;
  border: 2px solid var(--accent-color);
  padding: 8px;
  box-sizing: border-box;

  &.Big {
    width: 100%;
  }

  &__Title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    &_Version {
      font-size: 16px;
      font-weight: 300;
      height: fit-content;
      padding: 2px 6px;
      background-color: var(--accent-color);
      box-shadow: 0 0 5px 2px var(--accent-color);
    }
  }

  &__Desc {
    border-bottom: 1px solid var(--accent-color);
    margin-bottom: 4px;
  }

  &__Badges {
    display: flex;
    flex-wrap: wrap;

    &_Item {
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
}