.ProjectCard {
  background-color: #424;
  padding: 8px;
  box-sizing: border-box;
  border: 2px solid var(--accent-color);
  margin-bottom: 8px;

  &__Title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  &__Content, &__Links, &__Stack {
    border-top: 1px solid #d8858d;
    padding-top: 4px;
  }

  &__Content, &__Stack {
    margin-bottom: 4px;
  }

  &__Links {
    font-weight: 500;

    &_Link:not(:first-child) {
      margin-left: 4px;
    }
  }
}