.Projects {
  padding-top: var(--navbar-height);
  width: 100%;
  display: flex;
  flex-direction: column;

  &_Title {
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
    text-decoration: underline var(--accent-color);
  }

  &__Title {
    text-align: center;
    font-size: 2rem;
    border-bottom: 1px solid #fff;
    margin-bottom: 4px;
    padding-bottom: 4px;
  }
  
  &__List {
    margin-top: 8px;
  }
}