@import "../../Constants";

.GameList {
  margin: var(--navbar-height) 0;
  padding: 8px;
  background-color: #424;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #d8858d;

  &__Title {
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
    text-decoration: underline var(--accent-color);
  }

  &__Item {
    &_Title {
      text-align: center;
      margin-bottom: 6px;
    }

    &_Links {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &_Link {
        font-size: 20px;
        margin: 0 8px 8px;
        --color: var(--green-color);
        --hover-color: var(--green-darker-color);

        &--Outdated {
          --color: var(--red-color);
          --hover-color: var(--red-darker-color);
        }

        &--Beta {
          --color: var(--purple-color);
          --hover-color: var(--purple-darker-color);
        }
      }
    }
  }

  &__Actual, &__Outdated, &__Beta {
    text-align: center;
    font-size: 2rem;
    border-bottom: 1px solid #fff;
    margin-bottom: 4px;
    padding-bottom: 4px;
  }

  &__Actual {
    color: var(--green-lighter-color);
    border-color: var(--green-color);
  }

  &__Beta {
    color: var(--purple-lighter-color);
    border-color: var(--purple-color);
    cursor: pointer;

    &--NotCollapsed {
      border: none;
    }
  }

  &__Outdated {
    color: var(--red-lighter-color);
    border-color: var(--red-color);
    cursor: pointer;

    &--NotCollapsed {
      border: none;
    }
  }
}