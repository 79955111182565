@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
// @import url('https://storage.nix13.pw/fonts/dinpro/font.min.css');
@import url('https://files.nix13.pw/d/storage/ui-components/gradients.css');
@import url('https://files.nix13.pw/d/storage/ui-components/buttons.css');
@import "../../Constants";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root, .App {
  width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  color: white;
}

:root{
  scroll-behavior: smooth;
}

.App {
  background: #222;
  margin: var(--navbar-height) 0 0;
}

.Container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (min-width: calc(#{$containerWidth} + 1px)) {
    width: var(--container-witdh);
  }
  @media screen and (max-width: #{$containerWidth}) {
    width: 100%;
  }
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    color: #ddd;
  }
}

.Button--Outline {
  --color: #fff;
  --hover-color: #ddd;

  text-decoration: none;
  color: var(--color);
  border: 1px solid var(--color);
  padding: 4px 6px;
  border-radius: 4px;
  text-shadow: 0 0 20px;
  &:hover {
    color: var(--hover-color);
    border-color: var(--hover-color);
  }
}