@import "../../Constants";

.Main {
  width: 100%;
  height: calc(100vh - var(--navbar-height) - env(safe-area-inset-top));
  box-sizing: border-box;
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
  border-bottom: 5px solid var(--accent-color);

  &__Center {
    height: calc(100% - var(--navbar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_Name {
      color: var(--accent-color);
      font-size: 4rem;
      font-weight: 700;
    }
    &_SubName {
      font-size: 2rem;
      font-weight: 200;
    }
  }

  &__Links {
    height: 32px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_Link {
      display: flex;
      align-items: center;
      font-size: 2rem;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}