$containerWidth: 990px;
$accentColor: #d8858d;

$greenColor: #00FF7F;
$greenLighter: lighten($greenColor, 10%);
$greenDarker: darken($greenColor, 10%);

$redColor: #FA8072;
$redLighter: lighten($redColor, 10%);
$redDarker: darken($redColor, 10%);

$purpleColor: #DDA0DD;
$purpleLighter: lighten($purpleColor, 10%);
$purpleDarker: darken($purpleColor, 10%);

:root {
  --navbar-height: 32px;
  --container-witdh: #{$containerWidth};
  --accent-color: #{$accentColor};

  --green-color: #{$greenColor};
  --green-darker-color: #{$greenDarker};
  --green-lighter-color: #{$greenLighter};

  --red-color: #{$redColor};
  --red-darker-color: #{$redDarker};
  --red-lighter-color: #{$redLighter};

  --purple-color: #{$purpleColor};
  --purple-darker-color: #{$purpleDarker};
  --purple-lighter-color: #{$purpleLighter};
}